*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Sofia Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-weight: 400;
  line-height: 1.7;
  color: #777;
  padding: 10rem 37rem 5rem 37rem;
  text-align: center;
  background-image: url("./images/Scribbles.png");
  background-size: cover;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media (min-width: 768px) and (max-width: 1024px) {
  body {
    padding: 20rem;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  body {
    padding: 3rem;
  }
}
